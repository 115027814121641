
import {computed, defineComponent, onBeforeUnmount, onMounted, reactive, Ref, ref} from "vue";
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import Datatable from "@/components/c-datatable/Datatable.vue";
import Datarow from "@/components/c-datatable/Datarow.vue";
import {formatToDatatable} from "@/common/formaterToDatatable";
import moduleTable from "@/store/modules/werehouse/modules/transfers-table"
import moduleForm from "@/store/modules/werehouse/modules/transfers-form"
import ModalNewTransfer from "./components/ModalNewTransfer.vue"
import CrudActionsV2 from "@/components/c-module-master/CrudActionsV2.vue";
import {FormTypes} from "@/core/enums/form-types";
import {translate} from "@/core/plugins/i18n";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {TransferStatus} from "@/core/enums/transfer-status"
import TransferRow from "@/models/weewhouse/TransferRow";
import {useRouter} from "vue-router";
import {RouteNames} from "@/router/route-names";
import SwalMessageService from "@/core/services/SwalMessageService";
import {SwalIconType} from "@/core/enums/swal-icon-type";
import {toggleCollapse} from '@/core/helpers/dom';
import * as Validations from "yup";
import InputText from '@/components/forms/InputText.vue'
import Select from '@/components/forms/Select.vue'
import SelectFilterRemote from '@/components/forms/SelectFilterRemote.vue'
import DatePickerRange from '@/components/forms/DatePickerRange.vue'
import {Form} from 'vee-validate'
import FiltersTransfers from "@/models/weewhouse/filters/FiltersTransfers";
import _ from "lodash";
import ApiService from "@/core/services/ApiService";
import {getMessageError} from '@/core/helpers/messageFromPulmeros';
import PDFViewer from "@/views/cashregister/components/PDFViewer.vue";

export default defineComponent({
    components: {
        ModuleMaster
        , Datatable
        , Datarow
        , ModalNewTransfer
        , CrudActionsV2
        , Form
        , InputText
        , DatePickerRange
        , Select
        , SelectFilterRemote
        , PDFViewer
    },
    setup() {
        const openModal = ref<HTMLButtonElement>();
        const router = useRouter();
        const messageService = new SwalMessageService();
        const configTable = reactive([
            { PropName: "id", HeadLabel: "Id", Type: "text", VisibleInGrid: false },
            { PropName: "folio", HeadLabel: "Folio de Traspaso", Type: "text", VisibleInGrid: true },
            { PropName: "relatedFolio", HeadLabel: "Folio de Venta", Type: "text", VisibleInGrid: true },
            { PropName: "originWarehouseName", HeadLabel: "Origen", Type: "text", VisibleInGrid: true },
            { PropName: "destinationWarehouseName", HeadLabel: "Destino", Type: "text", VisibleInGrid: true },
            { PropName: "movementTypeName", HeadLabel: "Tipo de traspaso", Type: "text", VisibleInGrid: true },
            { PropName: "statusName", HeadLabel: "Estado", Type: "text", VisibleInGrid: true }
        ]);
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });
        const labels = reactive({
            placeHolderToFieldSearch: "Buscar Traspaso",
            titleNewCrudAction: "Nuevo Traspaso",
        });

        const formFilters = ref();
        const btnFilters = ref();
        const validationSchemaFilters = Validations.object().shape({
            folio: Validations.string().default('')
            , ocFolio: Validations.string().default('')
            , customerFolio: Validations.string().default('')
            , customerIds: Validations.array().default([])
            , startDate: Validations.date().default(undefined).optional()
            , endDate: Validations.date().default(undefined).optional()
            , filterType: Validations.string().default('')
            , status: Validations.string().default('').optional()
        })

        const idCollapse = ref('collapse-transfer-filters');
        const openPDF: Ref<HTMLElement | null> = ref(null);
        let pdfMovement = ref("");
        let intervalId;

        const optionsStatus = [
            { id: 0, name: "Captura Inicial"},
            { id: 1, name: "Finalizadas"},
            { id: 2, name: "Aprobadas"},
            { id: 3, name: "Canceladas"},
            { id: 4, name: "Precomprometidas"},
            { id: 5, name: "Comprometidas"}
        ]

        //computed
        const totalRecords = computed(() => moduleTable.totalRecords);
        const sizePage = computed(() => moduleTable.pagination.pageSize);
        const renderRows = computed(() => formatToDatatable(moduleTable.getRecordsTable, configTable));

        const handleChangePage = (page: number) => {
            moduleTable.CHANGE_PAGE({ currentPage: page });
        }
        const handleCrudAction = async (action: FormTypes = FormTypes.Create, id: string) => { 
            if (typeof action === "string") {
                action = action == "N" ? FormTypes.Create
                    : (action == "M" ? FormTypes.Update
                        : action == "V" ? FormTypes.Read : FormTypes.Create);
            }

            moduleForm.SET_FORM_TYPE(action);
            if (action != FormTypes.Create) {
                moduleForm.SEARCH_INFO_TRANSFER(id);
            } else {
                moduleForm.RESET_VALUES_FORM();
                moduleForm.RESET_ID_SELECT();
            }
            openModal.value?.click();
        }
      
        const goToDetailsTransfer = (row: TransferRow) => {
            moduleTable.SET_SELECT_ROW(row)
            router.push({
                name: RouteNames.TransfersDetails,
                params: {}
                , query: {
                    transferId: row.id
                }
            });
        }
        const sendCommitted = (row: TransferRow) => {
            messageService.confirm(SwalIconType.Question, `¿Esta seguro de la acción de Comprometer el traspaso <br>${row.folio}?`)
                .then(isConfirm => { 
                    if (isConfirm) {
                        moduleTable.CHANGE_STATUS(TransferStatus.Committed)
                            .then(isCommitted => { 
                                if (isCommitted) { 
                                    messageService.success(`Se ha comprometido el traspaso ${row.folio}`)
                                }
                        })
                     }
                })
        }
        const sendEndCapture = (row: TransferRow) => {
            messageService.confirm(SwalIconType.Question, `¿Esta seguro de la acción de Finalizar la captura del traspaso <br>${row.folio}?`)
                .then(isConfirm => {
                    if (isConfirm) {
                        moduleTable.CHANGE_STATUS(TransferStatus.EndCapture)
                            .then(IsSuccess => {
                                if (IsSuccess) {
                                    messageService.success(`Se ha Finalizado la captura del traspaso ${row.folio}`)
                                }
                            })
                    }
                })
        }
        
        const changeStatus = (row: TransferRow, newStatus: TransferStatus) => {
            moduleTable.SET_SELECT_ROW(row)

            switch (newStatus) {
                case TransferStatus.EndCapture:
                    sendEndCapture(row);
                    break;
                case TransferStatus.Committed:
                    sendCommitted(row);
                    break;
                default:
                    break;
            }
        }
        const printHeaderTransfer = (itemId) => { 
            ApiService.get(`Transfers/GetReceipt/${itemId}`)
            .then( resp => {
                if(resp.status == 200 && openPDF.value) {
                    pdfMovement.value = `data:application/pdf;base64,${resp.data}`;
                    openPDF.value?.click();
                }
            }).catch(error => {
                getMessageError(error, messageService);
            });
        }

        onMounted(() => {
            moduleTable.UPDATE_FILTERS({
                 endDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 17, 59, 59, 999).toISOString()
                , startDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 15, 0, 0, 0, 0).toISOString()
            });
            intervalId = setInterval(moduleTable.SEARCH_TABLE_DATA, 120000);
            resetFilterDateInitial();
            setCurrentPageBreadcrumbs(translate('BREADCRUMB.TRANSFERS'), [translate('BREADCRUMB.MODULES'), translate('BREADCRUMB.WAREHOUSE')]);
            moduleTable.getComboMovementTypes("");
        });

        onBeforeUnmount(() => {
            clearInterval(intervalId);
        });

        const resetFilterDateInitial = () => {
            const startDate = new Date();
            const endDate = new Date(startDate);
            startDate.setDate(endDate.getDate() - 15);
            formFilters.value.setFieldValue('startDate', startDate.toISOString())
            formFilters.value.setFieldValue('endDate', endDate.toISOString())
        }
        const searchTransfers = (filters: FiltersTransfers) => {
            moduleTable.UPDATE_FILTERS(filters)
            toggleCollapse("#" + idCollapse.value);
        }
        
        const searchCustomers = _.debounce((filterText: string) => {
            moduleTable.getComboCustomer(filterText);
        }, 550, { leading: false, trailing: true });

         const cleanFilters = () => {
            formFilters.value?.resetForm();
            resetFilterDateInitial();
            moduleTable.RESET_FILTERS();
            btnFilters.value?.click();
        }

        const customerOptions = computed(() => moduleTable.selects.customerOptions);
        const optionsTypes = computed(() => moduleTable.selects.movementOptions);

        return {
            headers
            , openPDF
            , pdfMovement
            , totalRecords
            , sizePage
            , renderRows
            , labels
            , idCollapse
            , formFilters
            , btnFilters
            , optionsStatus
            , validationSchemaFilters
            , openModal
            , handleChangePage
            , handleCrudAction
            , TransferStatus
            , goToDetailsTransfer
            , isInCaptureStatus: moduleTable.isInCaptureStatus
            , changeStatus
            , printHeaderTransfer
            , searchTransfers
            , customerOptions
            , optionsTypes
            , searchCustomers
            , cleanFilters
        }
    }
})
